// Constants
export const UNAUTH_TESTTIME = 1 * 60; //time in seconds for unauthenticated users
export const TESTTIME = 60 * 60; //time in seconds for authenticated users
export const RSM_PASS_PERCENTAGE = 80; //Road Signs and Markings Passing Percentage
export const RR_PASS_PERCENTAGE = 83;  //Rules Of The Road Passing Percentage
export const VC_PASS_PERCENTAGE = 85;  //Vehicle Control Passing Percentage
//API URL
export const API_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
'https://simplifydrive.co.za/api' 
: `http://localhost:3000/api`;

export const REACT_APP_BASE_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
'https://simplifydrive.co.za' 
: 'http://localhost:3001';

export const PAYFAST_URL = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
'https://www.payfast.co.za/eng/process' 
: 'http://sandbox.payfast.co.za/eng/process';

export const PASS_PHRASE = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
process.env.REACT_APP_PRODUCTION_PAYFAST_PASS_PHRASE 
: process.env.REACT_APP_DEVELOPMENT_PAYFAST_PASS_PHRASE;

export const MERCHANT_ID = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
process.env.REACT_APP_PRODUCTION_MERCHANT_ID 
: process.env.REACT_APP_DEVELOPMENT_MERCHANT_ID;

export const MERCHANT_KEY = process.env.REACT_APP_ENVIRONMENT === 'production' ? 
process.env.REACT_APP_PRODUCTION_MERCHANT_KEY 
: process.env.REACT_APP_DEVELOPMENT_MERCHANT_KEY;

//relative routes
export const BASE_URL = window.location.origin;
export const CLIENT_RELATIVE_PATH = "/client";
export const SCHOOL_RELATIVE_PATH = "/driving-school";
export const ADMIN_RELATIVE_PATH = "/admin";
// export const CLIENT_RELATIVE_PATH = genRandomPath(10); //no refresh path
